import React, { Suspense } from 'react';
// import JobSection from '../components/JobSection';
import jobs from '../data/jobs';

const PageHeader = React.lazy(() => import('../components/PageHeader'));
const JobSection = React.lazy(() => import('../components/JobSection'));

const Career = () => {
  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="Careers"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'Careers',
              to: '/careers',
            },
          ]}
        />
      </Suspense>

      {/* page header - end */}

      {/* tab section - start */}
      <Suspense fallback={<div>Loading...</div>}>
        <JobSection
          data={jobs}
          subHeading="Job Openings"
          className="tab-section-1"
        />
      </Suspense>

      {/* tab section - end */}
    </>
  );
};

export default Career;
