const services = [
  {
    icon: 'las la-cogs',
    heading: 'Custom Software Development',
    excerpt:
      'Creating scalable and secure software solutions, including enterprise apps, CRM systems, mobile apps, and web platforms.',
    to: '/service-custom-software-development',
  },
  {
    icon: 'las la-cloud',
    heading: 'Cloud Computing',
    excerpt:
      'Providing cloud migration, infrastructure management, and optimization for flexibility, scalability, and cost efficiency.',
    to: '/service-cloud-computing',
  },
  {
    icon: 'las la-shield-alt',
    heading: 'Cybersecurity Solutions',
    excerpt:
      'Offering risk assessments, vulnerability testing, and security measures to protect data and ensure compliance.',
    to: '/service-cybersecurity',
  },
  {
    icon: 'las la-laptop-code',
    heading: 'Web Development',
    excerpt:
      'Building responsive and engaging websites, including design, development, e-commerce, content management.',
    to: '/service-web-development',
  },
  {
    icon: 'las la-tools',
    heading: 'Managed IT Services',
    excerpt:
      'Handling IT operations with 24/7 monitoring, maintenance, and support to keep your infrastructure stable.',
    to: '/service-managed-it',
  },
  {
    icon: 'las la-chart-bar',
    heading: 'Data Analytics',
    excerpt:
      'Transforming data into actionable insights with tools for business intelligence, data warehousing, and predictive analytics.',
    to: '/service-data-analytics',
  },
  {
    icon: 'las la-headset',
    heading: 'IT Support and Maintenance',
    excerpt:
      'Providing troubleshooting, repair, routine maintenance, and upgrades for smooth IT system operations.',
    to: '/service-it-support-maintenance',
  },
  {
    icon: 'las la-lightbulb',
    heading: 'IT Consulting',
    excerpt:
      'Delivering expert guidance on technology strategy, infrastructure, and digital transformation.',
    to: '/service-it-consulting',
  },
  {
    icon: 'las la-network-wired',
    heading: 'Network Solutions',
    excerpt:
      'Optimizing network design, implementation, and management for reliable connectivity and performance.',
    to: '/service-network-solutions',
  },
  {
    icon: 'las la-chalkboard-teacher',
    heading: 'IT Training and Support',
    excerpt:
      'Offering customized training programs to empower your team with the latest IT skills and knowledge.',
    to: '/service-it-training-support',
  },
];

export default services;
