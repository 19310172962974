import React, { Suspense } from 'react';

import SectionHeading from '../components/SectionHeading';

const PageHeader = React.lazy(() => import('../components/PageHeader'));
const GoogleMap = React.lazy(() => import('../components/GoogleMap'));
const ImageSlider = React.lazy(() => import('../components/ImageSlider'));
const Button = React.lazy(() => import('../components/Button'));

const Contact = () => {
  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="Contact"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'Contact',
              to: '/contact',
            },
          ]}
        />
      </Suspense>
      {/* page header - end */}

      {/* contact details - start */}
      <div className="contact-details">
        <div className="contact-details-wrapper">
          <div className="container">
            {/* contact details heading - start */}
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <SectionHeading
                  subHeading="Many ways to reach us today"
                  heading="get in touch"
                  icon="las la-handshake"
                  additionalClasses="center width-55"
                />
              </div>
            </div>
            {/* contact details heading - end */}
            {/* contact details row - start */}
            <div className="row gx-5 details-row">
              <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="app-feature-single app-feature-single-1">
                  <div className="app-feature-single-wrapper">
                    <div className="icon">
                      <i className="las la-envelope-open"></i>
                    </div>
                    <h3 className="c-dark">
                      <a
                        href="mailto:hr@avodocorp.com"
                        className="c-dark"
                        style={{ textDecoration: 'none' }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.textDecoration = 'underline')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.textDecoration = 'none')
                        }
                      >
                        Email Us
                      </a>
                    </h3>
                    <p className="c-grey">
                      <span>Avodocorp.com</span>
                    </p>
                    <p className="c-grey">
                      <span>hr@avodocorp.com</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <div className="app-feature-single app-feature-single-1">
                  <div className="app-feature-single-wrapper">
                    <div className="icon">
                      <i className="las la-map-marked-alt"></i>
                    </div>
                    <h3 className="c-dark">
                      <a
                        href="https://maps.app.goo.gl/yqCYnQ325J5PJPvW7"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-dark"
                        style={{ textDecoration: 'none' }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.textDecoration = 'underline')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.textDecoration = 'none')
                        }
                      >
                        Find Us
                      </a>
                    </h3>
                    <p className="c-grey">195 Dien Bien Phu St, Ward 15</p>
                    <p className="c-grey">Binh Thanh Dist, Ho Chi Minh City</p>
                  </div>
                </div>
              </div>
            </div>
            {/* contact details row - end */}
          </div>
        </div>
      </div>
      {/* contact details - end */}

      {/* contact form section - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <div className="contact-form-section">
          <div className="contact-form-section-wrapper">
            <div className="container">
              <div className="row gx-5 contact-form-section-row">
                <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                  <GoogleMap />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>

      {/* contact form section - end */}

      {/* instagram - start */}
      <div className="instagram">
        <div className="instagram-wrapper">
          <Suspense fallback={<div>Loading ...</div>}>
            <ImageSlider />
            <div className="button-wrapper">
              <Button to="/" content="Follow us" type="button-premium" />
            </div>
          </Suspense>
        </div>
      </div>
      {/* instagram - end */}
    </>
  );
};

export default Contact;
