import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';

import CommentSingle from '../components/CommentSingle';

// Replace this with your actual blog data import or API call
import blog from '../data/blog';

const PageHeader = React.lazy(() => import('../components/PageHeader'));

const BlogDetail = () => {
  const { id } = useParams(); // Get the id from the URL

  // Find the blog post based on the id
  const post = blog.find((post) => post.id === parseInt(id));

  if (!post) {
    return <p>Post not found</p>;
  }

  const getCommentsLength = (comment) => {
    let count = comment.length;
    comment.forEach((element) => {
      if (element.reply) count += getCommentsLength(element.reply);
    });
    return count;
  };

  const commentDisplay = (comment, index) => {
    if (comment.reply) {
      return (
        <CommentSingle
          key={index}
          image={comment.image}
          name={comment.name}
          date={comment.date}
          comment={comment.comment}
          isReply={true}
        >
          {comment.reply
            ? comment.reply.map((reply, i) => {
                return commentDisplay(reply, i);
              })
            : null}
        </CommentSingle>
      );
    }
    return (
      <CommentSingle
        key={index}
        image={comment.image}
        name={comment.name}
        date={comment.date}
        comment={comment.comment}
        isReply={true}
      />
    );
  };

  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="Blog"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'Blog',
              to: '/blog',
            },
          ]}
        />
      </Suspense>

      {/* page header - end */}

      {/* blog detail - start */}
      <div className="blog-detail">
        <div className="blog-detail-wrapper">
          {/* blog detail content - start */}
          <div className="container">
            {/* blog detail thumbnail - start */}
            {/* <div className="row">
              <div className="col-lg-12 offset-lg-0 col-8 offset-1">
                <div className="blog-detail-thumbnail drop-shadow">
                  <img src={post.topImage} alt="blog-detail-thumbnail" />
                </div>
              </div>
            </div> */}
            {/* blog detail thumbnail - end */}
            {/* blog detail content - start */}
            <div className="row">
              <div className="col-md-8 offset-md-2 col-10 offset-1">
                <div className="blog-detail-content c-grey">
                  <h1 className="c-dark f-w-700">{post.title}</h1>
                  <div className="blog-single-details">
                    <div className="comments">
                      <i className="las la-comment-alt"></i>
                      {getCommentsLength(post.comments)}
                    </div>
                    <div className="separator"></div>
                    <div className="date">
                      <i className="las la-calendar"></i>
                      {post.date}
                    </div>
                  </div>
                  <p>{post.demo}</p>
                  {post.content.map((item) => (
                    <p key={item.id}>
                      <strong>{item.title}:</strong> {item.description}
                    </p>
                  ))}

                  <h3 className="c-dark f-w-700">Conclusion</h3>
                  <p>{post.conclusion}</p>
                  <div className="social social-gradient">
                    <p className="paragraph-small c-grey-1">Share this post:</p>
                    <ul>
                      <li className="twitter">
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li className="facebook">
                        <Link to="#">
                          <i className="fab fa-facebook"></i>
                        </Link>
                      </li>
                      <li className="linkedin">
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li className="youtube">
                        <Link to="#">
                          <i className="fab fa-youtube"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* blog detail content - end */}
          </div>
          {/* blog detail content - end */}
        </div>
      </div>
      {/* blog detail - end */}

      {/* comments - start */}
      <div className="comments">
        <div className="comments-wrapper">
          <div className="container">
            {/* comments list - start */}
            <div className="row">
              <div className="col-md-8 offset-md-2 col-10 offset-1">
                <div className="comments-list">
                  {/* comments heading - start */}
                  <div className="comments-heading">
                    <h3 className="c-dark f-w-700">
                      {getCommentsLength(post.comments)} replies
                    </h3>
                  </div>
                  {/* comments heading - end */}
                  {post.comments.map(commentDisplay)}
                </div>
              </div>
            </div>
            {/* comments list - end */}
            {/* comments form - start */}
            <div className="row">
              <div className="col-md-8 offset-md-2 col-10 offset-1">
                <div className="comments-form">
                  <div className="comments-form-wrapper">
                    {/* comments form heading - start */}
                    <div className="comments-form-heading">
                      <h3>Write a reply</h3>
                    </div>
                    {/* comments form heading - end */}
                    {/* comments form - start */}
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-floating">
                            <input
                              className="input form-control"
                              id="name-field"
                              type="text"
                              placeholder="Name *"
                            />
                            <label htmlFor="name-field">Name *</label>
                          </div>
                          <div className="form-floating">
                            <input
                              className="input form-control"
                              id="email-field"
                              type="email"
                              placeholder="Email *"
                            />
                            <label htmlFor="email-field">Email *</label>
                          </div>
                          <div className="form-floating">
                            <input
                              className="input form-control"
                              id="website-field"
                              type="text"
                              placeholder="Website"
                            />
                            <label htmlFor="website-field">Website</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-floating textarea-form">
                            <textarea
                              className="input textarea form-control"
                              id="comment-field"
                              placeholder="Write reply *"
                            ></textarea>
                            <label htmlFor="comment-field">Write reply *</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button type="submit" className="button button-3">
                            <span className="button-inner">
                              <span className="button-content">
                                <span className="text">Submit Reply</span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* comments form - end */}
                  </div>
                </div>
              </div>
            </div>
            {/* comments form - end */}
          </div>
        </div>
      </div>
      {/* comments - end */}
    </>
  );
};

export default BlogDetail;
