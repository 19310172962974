import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const BackgroundAnimation = () => {
  const box = useRef();
  const tl = useRef();

  useEffect(() => {
    // Optimize by only running effect once on mount
    tl.current = gsap.timeline({ repeat: -1 });

    tl.current.to(box.current, {
      duration: 40, // Increase duration to reduce rapid frame changes
      backgroundPosition: `-1850px center`,
      ease: "linear", // Use "linear" for better syntax
    });

    return () => {
      tl.current.kill(); // Clean up on unmount
    };
  }, []); // Empty dependency array to avoid reruns

  // Handle document visibility to pause animation when the tab is inactive
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        tl.current.pause(); // Pause when the tab is inactive
      } else {
        tl.current.resume(); // Resume when the tab is active
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div
      ref={box}
      className="background-pattern-img background-loop"
      style={{
        backgroundImage: `url('/assets/patterns/pattern.webp')`,
        willChange: 'background-position', // Optimize GPU usage
      }}
    ></div>
  );
};

export default BackgroundAnimation;
