import React from 'react';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="footer-row">
                <div className="footer-detail">
                  <Link to="#">
                    <img src={'/assets/logo/logo.webp'} alt="footer-logo" />
                  </Link>
                  <p className="c-grey-1">
                    Our expert team combines deep technical knowledge with a
                    customer-centric approach to deliver tailored IT solutions
                    that meet your specific needs.
                  </p>
                  <div className="links">
                    <a
                      className="link-underline"
                      href="mailto:hr@avodocorp.com"
                    >
                      <span>hr@avodocorp.com</span>
                    </a>
                  </div>
                </div>
                <div className="footer-list">
                  <h6>Menu</h6>
                  <ul>
                    <li>
                      <Link to="about" className="link-underline">
                        <span>About</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="services" className="link-underline">
                        <span>Services</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="contact" className="link-underline">
                        <span>Contact</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-list">
                  <h6>Explore</h6>
                  <ul>
                    <li>
                      <Link to="careers" className="link-underline">
                        <span>Careers</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="blog" className="link-underline">
                        <span>Blog</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="footer-copyright c-grey-1">
                <h6>
                  &copy; 2018 AVODOCORP. Design & Develop with{' '}
                  <i className="las la-heart"></i> by AVODOCORP
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div
          className="footer-patternTop"
          style={{ backgroundImage: `url('/assets/patterns/pattern-2.webp')` }}
        ></div>
        <div
          className="footer-pattern"
          style={{ backgroundImage: `url('/assets/patterns/pattern-1.webp')` }}
        ></div>
      </div>
    </footer>
  );
};

export default Footer;
