const ourfeatures = [
  {
    question: 'Innovation',
    answer:
      'We stay ahead of technology trends and foster a culture of creativity and continuous learning to provide cutting-edge solutions.',
  },
  {
    question: 'Excellence',
    answer:
      'We aim for the highest standards in all we do, ensuring quality and reliability through attention to detail and rigorous quality assurance.',
  },
  {
    question: 'Integrity',
    answer:
      'Honesty and transparency are central to our practices, building trust through ethical standards and alignment between actions and words.',
  },
  {
    question: 'Client-Centricity',
    answer:
      "We prioritize understanding and addressing our clients' needs with tailored solutions and open communication.",
  },
  {
    question: 'Collaboration',
    answer:
      'We value teamwork and the collective expertise of our diverse team, working together to achieve exceptional results.',
  },
  {
    question: 'Agility',
    answer:
      'We adapt swiftly to technological changes and industry shifts, maintaining relevance and effectively responding to evolving needs.',
  },
  {
    question: 'Accountability',
    answer:
      'We take responsibility for our actions and outcomes, ensuring transparency and prompt resolution of any issues.',
  },
  {
    question: 'Sustainability',
    answer:
      'We promote environmentally friendly practices and support clients in achieving their sustainability goals.',
  },
  {
    question: 'Empowerment',
    answer:
      'We provide the tools and support necessary for team and client success, fostering growth and a positive work environment.',
  },
  {
    question: 'Excellence in Service',
    answer:
      'We are dedicated to delivering superior customer service, building lasting relationships through responsiveness and attention.',
  },
];

export default ourfeatures;
