const jobs = [
  // Java Developers
  {
    icon: 'lab la-java',
    title: 'Java Developer',
    content: (
      <>
        <p className="paragraph-big">Last updated on 2024</p>
        <p>
          We are seeking a skilled Java Developer to join our development team.
          In this position, you will design, develop, and maintain Java
          applications that advance our business goals. You will be involved in
          various projects, from creating new features to improving existing
          systems, while working with cross-functional teams to deliver
          high-quality software.
        </p>
        <h3 className="c-dark">Responsibilities</h3>
        <ul className="icon-list icon-list-1">
          <li>
            <span>
              Design, code, debug, and develop software solutions for Java
              applications.
            </span>
          </li>
          <li>
            <span>
              Utilize industry best practices to develop Java-based software.
            </span>
          </li>
          <li>
            <span>
              Provide technical support to users and troubleshoot software issues.
            </span>
          </li>
          <li>
            <span>
              Collaborate with other engineers to ensure high-quality code.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">Qualifications</h3>
        <ul className="icon-list icon-list-2">
          <li>
            <span>At least 5 years of experience in Java development.</span>
          </li>
          <li>
            <span>Extensive knowledge of website design and programming.</span>
          </li>
          <li>
            <span>
              Familiarity with hardware and operating systems (experience with Unix-based systems is a plus).
            </span>
          </li>
          <li>
            <span>
              Understanding of relational databases like Oracle, SQL Server, or DB2.
            </span>
          </li>
          <li>
            <span>
              Proficiency in Java and frameworks such as Spring, Hibernate, or JEE.
            </span>
          </li>
          <li>
            <span>
              Experience with web services and RESTful APIs.
            </span>
          </li>
          <li>
            <span>
              Knowledge of SQL and experience with database systems (e.g., MySQL, Oracle).
            </span>
          </li>
          <li>
            <span>
              Experience with version control systems like Git.
            </span>
          </li>
        </ul>
      </>
    ),
  },

  // Business Analyst
  {
    icon: 'las la-chart-area',
    title: 'Business Analyst',
    content: (
      <>
        <p className="paragraph-big">Last updated on 2024</p>
        <p>
          We are looking for a highly skilled and driven Business Analyst to join our vibrant team. This role involves analyzing business processes, identifying areas for improvement, and offering actionable insights to support strategic decision-making. The ideal candidate will have strong analytical abilities, a solid understanding of business operations, and the capacity to convert complex data into clear, actionable recommendations.
        </p>
        <h3 className="c-dark">Responsibilities</h3>
        <ul className="icon-list icon-list-1">
          <li>
            <span>
              Collaborate with stakeholders from various business units (e.g., sales, finance, security, compliance) to develop analyses and documentation in a cooperative manner.
            </span>
          </li>
          <li>
            <span>
              Communicate effectively with production, managerial, and executive teams.
            </span>
          </li>
          <li>
            <span>
              Continuously evaluate, analyze, and convey system requirements, maintaining system processes and delivering monthly status reports to relevant parties.
            </span>
          </li>
          <li>
            <span>
              Build and sustain meaningful relationships with partners for optimized system integration, and address questions and concerns from managers and executives with well-supported research and recommendations.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">Qualifications</h3>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Over 5 years of experience in analytics and systems development.</span>
          </li>
          <li>
            <span>High proficiency in SQL and database management.</span>
          </li>
          <li>
            <span>Experience in creating process documentation and reports.</span>
          </li>
          <li>
            <span>Excellent communication skills with the ability to turn data into actionable insights.</span>
          </li>
        </ul>
      </>
    ),
  },

  // Devops
  {
    icon: 'las la-infinity',
    title: 'DevOps Engineer',
    content: (
      <>
        <p className="paragraph-big">Last updated on 2024</p>
        <p>
          We are looking for a skilled and proactive DevOps Engineer to join our team. In this position, you will oversee and manage the infrastructure and processes essential for our development and deployment pipelines. You will collaborate closely with development, operations, and quality assurance teams to ensure effective and reliable software delivery, and manage the continuous integration and deployment of applications.
        </p>
        <h3 className="c-dark">Responsibilities</h3>
        <ul className="icon-list icon-list-1">
          <li>
            <span>
              Design, implement, and manage CI/CD pipelines to enhance software development and deployment efficiency.
            </span>
          </li>
          <li>
            <span>
              Automate and refine system configuration, monitoring, and management tasks to boost performance and reliability.
            </span>
          </li>
          <li>
            <span>
              Work with development and operations teams to troubleshoot and resolve issues related to application performance, system stability, and infrastructure.
            </span>
          </li>
          <li>
            <span>
              Monitor system performance, conduct routine maintenance, and apply best practices in infrastructure management.
            </span>
          </li>
          <li>
            <span>
              Ensure system security and compliance by applying patches, conducting vulnerability assessments, and enforcing access controls.
            </span>
          </li>
          <li>
            <span>
              Develop and maintain infrastructure as code (IaC) using tools such as Terraform or Ansible.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">Qualifications</h3>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Bachelor’s degree in Computer Science, Engineering, or a related field, or equivalent experience.</span>
          </li>
          <li>
            <span>Proven track record as a DevOps Engineer or similar role with a deep understanding of the software development lifecycle.</span>
          </li>
          <li>
            <span>Proficiency in scripting languages (e.g., Python, Bash, PowerShell) and automation tools.</span>
          </li>
          <li>
            <span>Experience with containerization technologies (e.g., Docker, Kubernetes) and orchestration.</span>
          </li>
          <li>
            <span>Familiarity with cloud platforms (e.g., AWS, Azure, Google Cloud) and their services.</span>
          </li>
          <li>
            <span>Strong knowledge of CI/CD tools (e.g., Jenkins, GitLab CI, Travis CI) and version control systems (e.g., Git).</span>
          </li>
          <li>
            <span>Experience with monitoring and logging tools (e.g., Prometheus, Grafana, ELK Stack).</span>
          </li>
        </ul>
      </>
    ),
  },

  // React Native
  {
    icon: 'lab la-react',
    title: 'React Native Developer',
    content: (
      <>
        <p className="paragraph-big">Last updated on 2024</p>
        <p>
          We are looking for a skilled React Native Developer to join our team. In this role, you will be responsible for creating and maintaining high-quality, cross-platform mobile applications using React Native. You will work closely with product managers, designers, and other developers to build mobile experiences that are both seamless and responsive, aligning with user needs and business goals.
        </p>
        <h3 className="c-dark">Responsibilities</h3>
        <ul className="icon-list icon-list-1">
          <li>
            <span>
              Develop and maintain mobile applications for iOS and Android using React Native.
            </span>
          </li>
          <li>
            <span>
              Collaborate with product managers and designers to translate user requirements and design concepts into functional and engaging apps.
            </span>
          </li>
          <li>
            <span>
              Write clean, efficient, and maintainable code, adhering to best practices in mobile development.
            </span>
          </li>
          <li>
            <span>
              Conduct thorough testing and optimization to ensure app performance, quality, and responsiveness.
            </span>
          </li>
          <li>
            <span>
              Diagnose and resolve issues promptly to maintain application stability.
            </span>
          </li>
          <li>
            <span>
              Stay current with industry trends, tools, and technologies related to React Native.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">Qualifications</h3>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Bachelor’s degree in Computer Science, Engineering, or a related field, or equivalent experience.</span>
          </li>
          <li>
            <span>Demonstrated experience as a React Native Developer with a strong portfolio of React Native mobile applications.</span>
          </li>
          <li>
            <span>Proficiency in JavaScript and familiarity with modern libraries and frameworks such as Redux and React Navigation.</span>
          </li>
          <li>
            <span>Solid understanding of mobile development principles and best practices for both iOS and Android platforms.</span>
          </li>
          <li>
            <span>Experience with RESTful APIs and integrating third-party services.</span>
          </li>
        </ul>
      </>
    ),
  },

  // Vuejs
  {
    icon: 'lab la-vuejs',
    title: 'Vue.js Developer',
    content: (
      <>
        <p className="paragraph-big">Last updated on 2024</p>
        <p>
          We are seeking an experienced Vue.js Developer to join our team. In this role, you will be tasked with creating and maintaining dynamic and responsive web applications using Vue.js. You will work closely with front-end and back-end developers, as well as designers, to deliver smooth user experiences and meet business objectives.
        </p>
        <h3 className="c-dark">Responsibilities</h3>
        <ul className="icon-list icon-list-1">
          <li>
            <span>
              Design and develop web applications using Vue.js, ensuring high performance and responsiveness.
            </span>
          </li>
          <li>
            <span>
              Work with product managers and designers to convert user requirements and design concepts into functional applications.
            </span>
          </li>
          <li>
            <span>
              Write clean, efficient, and maintainable code according to front-end development best practices.
            </span>
          </li>
          <li>
            <span>
              Optimize application performance and quality through rigorous testing and fine-tuning.
            </span>
          </li>
          <li>
            <span>
              Troubleshoot and resolve issues to improve application stability.
            </span>
          </li>
          <li>
            <span>
              Keep abreast of the latest Vue.js developments, tools, and technologies.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">Qualifications</h3>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Bachelor’s degree in Computer Science, Engineering, or a related field, or equivalent experience.</span>
          </li>
          <li>
            <span>Demonstrated experience as a Vue.js Developer with a strong portfolio of Vue.js applications.</span>
          </li>
          <li>
            <span>Proficiency in JavaScript and familiarity with modern JavaScript libraries and frameworks.</span>
          </li>
          <li>
            <span>Comprehensive understanding of front-end development principles and practices.</span>
          </li>
          <li>
            <span>Experience with RESTful APIs and third-party service integration.</span>
          </li>
        </ul>
      </>
    ),
  }

];

export default jobs;
