import React, { Suspense } from 'react';

import SectionHeading from '../components/SectionHeading';
// import AppFeatureSingle from '../components/AppFeatureSingle';

import services from '../data/services';
import ourfeatures from '../data/ourfeatures';

const PageHeader = React.lazy(() => import('../components/PageHeader'));

const AppFeatureSingle = React.lazy(() =>
  import('../components/AppFeatureSingle'),
);

const Services = () => {
  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="Services"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'Services',
              to: '/services',
            },
          ]}
        />
      </Suspense>

      {/* page header - end */}

      <div className="app-feature app-feature-2">
        <div className="app-feature-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                <SectionHeading
                  heading="our services"
                  subHeading="We deliver IT services that drive growth"
                  icon="las la-cog"
                  additionalClasses="center width-64"
                />
              </div>
            </div>
            <div className="row gx-5 gy-5">
              {services.map((element, key) => {
                return (
                  <div
                    className="col-xl-6 col-lg-6 offset-lg-0 col-md-10 offset-md-0 col-10 offset-1"
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <AppFeatureSingle
                        icon={element.icon}
                        heading={element.heading}
                        excerpt={element.excerpt}
                        containerClass="app-feature-single-1"
                      />
                    </Suspense>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* faq section - start */}
      <div className="faq-section">
        <div className="faq-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                <SectionHeading
                  icon="las la-file-alt"
                  heading="features"
                  subHeading="Our values drive excellence"
                  additionalClasses="center width-64"
                />
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 col-md-8 col-10">
                <div className="faq-wrapper">
                  <div className="faq" id="faq-accordion">
                    {ourfeatures.map((element, key) => {
                      return (
                        <div className="accordion-item" key={key}>
                          <div className="accordion-header" id={`faq-${key}`}>
                            <button
                              className={`accordion-button ${
                                key !== 0 ? 'collapsed' : ''
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#faq-collapse-${key}`}
                              aria-expanded={key === 0 ? 'true' : 'false'}
                              aria-controls={`faq-collapse-${key}`}
                            >
                              <span>{element.question}</span>
                            </button>
                          </div>
                          <div
                            id={`faq-collapse-${key}`}
                            className={`accordion-collapse collapse ${
                              key === 0 ? 'show' : ''
                            }`}
                            aria-labelledby={`faq-${key}`}
                            data-bs-parent="#faq-accordion"
                          >
                            <div className="accordion-body">
                              <p>{element.answer}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* faq section - end */}
    </>
  );
};

export default Services;
