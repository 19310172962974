import React, { Suspense } from 'react';

// import BlogSingle from '../components/BlogSingle';
import blog from '../data/blog';

const PageHeader = React.lazy(() => import('../components/PageHeader'));
const BlogSingle = React.lazy(() => import('../components/BlogSingle'));

const Blog = () => {
  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="Blog"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'Blog',
              to: '/blog',
            },
          ]}
        />
      </Suspense>

      {/* page header - end */}

      {/* blog section - start */}
      <div className="blog-section blog-section-1">
        <div className="blog-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              {blog.map((post) => {
                return (
                  <div
                    className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1"
                    key={post.id}
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <BlogSingle element={post} />
                    </Suspense>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* blog section - end */}
    </>
  );
};

export default Blog;
