import React, { Suspense } from 'react';

// components
import SectionHeading from '../components/SectionHeading';
import Button from '../components/Button';
// import ContactForm1 from '../components/ContactForm1';
// import ContactForm2 from '../components/ContactForm2';
const ContactForm1 = React.lazy(() => import('../components/ContactForm1'));
const ContactForm2 = React.lazy(() => import('../components/ContactForm2'));

const Index = () => {
  return (
    <>
      {/* hero - start */}
      <div className="hero hero-1">
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                <div className="hero-content">
                  <h1 className="c-dark">Software solutions in your hands</h1>
                  <p className="large c-grey">
                    Our IT company specializes in delivering innovative software
                    solutions that drive
                    <b> business success</b> and <b>efficiency</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="hero-image">
                  <img
                    className="drop-shadow"
                    src={'/assets/home/hero-phone.webp'}
                    alt="hero"
                  />
                  <div className="hero-absolute-image">
                    <img
                      src={'/assets/home/artwork.webp'}
                      alt="artwork"
                      style={{ maxWidth: '805', maxHeight: '976' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* hero - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-1 feature-section-spacing-2">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                <div className="feature-section-image">
                  <img
                    src={'/assets/home/feature-section-1-img.webp'}
                    className="image"
                    alt="feature-fore"
                    style={{ maxWidth: '257', maxHeight: '428' }}
                  />
                  <img
                    src={'/assets/home/feature-section-1-phone.webp'}
                    className="phone"
                    alt="phone"
                  />
                  <div className="background-pattern background-pattern-radius-reverse">
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                <div className="feature-section-content">
                  <SectionHeading
                    icon="las la-cog"
                    heading="Discover"
                    subHeading="Discover our unique features"
                  />
                  <div className="icon-text-1-group">
                    <div className="icon-text-1">
                      <i className="las la-graduation-cap"></i>
                      <div>
                        <h4 className="c-dark">Expertise</h4>
                        <p className="c-grey">
                          Our team consists of industry veterans with deep
                          experience across various IT sectors, guaranteeing you
                          receive high-quality solutions and support.
                        </p>
                      </div>
                    </div>
                    <div className="icon-text-1">
                      <i className="las la-lightbulb"></i>
                      <div>
                        <h4 className="c-dark">Innovation</h4>
                        <p className="c-grey">
                          We lead in adopting the latest technology trends,
                          integrating cutting-edge advancements into our
                          solutions to ensure your business remains competitive.
                        </p>
                      </div>
                    </div>
                    <div className="icon-text-1">
                      <i className="las la-handshake"></i>
                      <div>
                        <h4 className="c-dark">Customer Focus</h4>
                        <p className="c-grey">
                          We are committed to understanding your unique
                          requirements and providing customized solutions that
                          address your specific challenges and goals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <Button to="/contact" content="Get Started" type="button-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* feature section - start */}
      <div className="feature-section feature-section-0 feature-section-spacing-1">
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="feature-section-content">
                  <SectionHeading
                    icon="las la-cog"
                    heading="features"
                    subHeading="Rich and full of features"
                  />
                  <div className="icon-text-group">
                    <div className="icon-text">
                      <i className="las la-check-circle"></i>
                      <h4 className="c-dark">Commitment to Quality</h4>
                      <p className="c-grey">
                        We uphold the highest standards of quality in every
                        aspect of our work, ensuring excellence from service
                        delivery to customer support.
                      </p>
                    </div>
                    <div className="icon-text">
                      <i className="las la-award"></i>
                      <h4 className="c-dark">Proven Track Record</h4>
                      <p className="c-grey">
                        Our history of success with diverse clients across
                        multiple industries showcases our ability to provide
                        effective and dependable IT solutions.
                      </p>
                    </div>
                  </div>
                  <Button to="/contact" content="Get Started" type="button-2" />
                </div>
              </div>
              <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="feature-section-image">
                  <img
                    src={'/assets/home/feature-section-2-phone.webp'}
                    className="phone"
                    alt="phone"
                  />
                  <div className="background-pattern background-pattern-radius">
                    <div className="background-pattern-gradient"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}

      {/* contact form section - start */}
      <div className="contact-form-section">
        <div className="contact-form-section-wrapper">
          <div className="container">
          <Suspense fallback={<div>Loading...</div>}>

            <div className="row gx-5 contact-form-section-row">
              <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                  {/* contact form - start */}
                  <ContactForm1 />
                {/* contact form - end */}
              </div>
              <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                {/* newsletter form - start */}
                <ContactForm2 />
                {/* newsletter form - end */}
              </div>
            </div>
            </Suspense>

          </div>
        </div>
      </div>
      {/* contact form section - end */}
    </>
  );
};

export default Index;
