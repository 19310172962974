import React, { Suspense } from 'react';
// components
import SectionHeading from '../components/SectionHeading';
// import TestimonialSlider from '../components/TestimonialSlider';
// import AppFeatureSingle from '../components/AppFeatureSingle';
const PageHeader = React.lazy(() => import('../components/PageHeader'));

const TestimonialSlider = React.lazy(() =>
  import('../components/TestimonialSlider'),
);
const AppFeatureSingle = React.lazy(() =>
  import('../components/AppFeatureSingle'),
);

const About = () => {
  return (
    <>
      {/* page header - start */}
      <Suspense fallback={<div>Loading ...</div>}>
        <PageHeader
          title="About Us"
          pages={[
            {
              title: 'Home',
              to: '/',
            },
            {
              title: 'About',
              to: '/about',
            },
          ]}
        />
      </Suspense>

      {/* page header - end */}

      {/* about section - start */}
      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="container">
            {/* first half - start */}
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="about-section-content c-grey">
                  <div className="section-heading">
                    <div className="sub-heading c-blue upper ls-1">
                      <i className="las la-user-circle"></i>
                      <h5>introduction</h5>
                    </div>
                    <div className="main-heading c-dark">
                      <h1>About Avodo Corp</h1>
                    </div>
                  </div>
                  <p className="paragraph-big">
                    Our expert team combines deep technical knowledge with a
                    customer-centric approach to deliver tailored IT solutions
                    that meet your specific needs.
                  </p>
                  <p>
                    Avodo Corp is a premier IT services provider committed to
                    empowering businesses with innovative technology solutions.
                    Founded with the goal of driving digital transformation, we
                    specialize in creating cutting-edge solutions tailored to
                    meet the unique challenges and opportunities of today’s
                    fast-paced technological landscape.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                <div className="about-section-image">
                  <div className="pattern-image pattern-image-1">
                    <div className="pattern-image-wrapper">
                      <img
                        className="drop-shadow-1"
                        src={'/assets/about/about-image.webp'}
                        height="450"
                        alt="pattern"
                      />
                      <div className="background-pattern background-pattern-radius drop-shadow-1">
                        <div className="background-pattern-gradient"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* first half - end */}
            {/* second half - start */}
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <h3 className="c-dark second-half-heading">
                  Why we are different ?
                </h3>
              </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <div className="row gx-5 about-row">
                <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                  <AppFeatureSingle
                    icon="las la-bullseye"
                    heading="Our Mission"
                    excerpt="Our mission is to equip businesses with the tools and expertise necessary to navigate the complexities of modern technology and achieve their strategic goals. We are dedicated to delivering high-quality IT services that foster growth, enhance efficiency, and drive success. By leveraging the latest technological advancements, we help our clients stay ahead of the curve and seize emerging opportunities."
                    link="/features-2"
                    containerClass="app-feature-single-1"
                  />
                </div>

                <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                  <AppFeatureSingle
                    icon="las la-users-cog"
                    heading="Our Approach"
                    excerpt="At Avodo Corp, we take a client-centric approach to ensure our services align with your business goals. Our team of highly skilled professionals works closely with you to understand your needs, offering personalized solutions that deliver tangible results. We prioritize transparency, collaboration, and innovation in every project, aiming to exceed expectations and build lasting partnerships."
                    link="/features-2"
                    containerClass="app-feature-single-1"
                  />
                </div>
                <div
                  className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1"
                  style={{ marginTop: '2rem' }}
                >
                  <AppFeatureSingle
                    icon="las la-eye"
                    heading="Our Vision"
                    excerpt="To be the leading IT partner, recognized for our excellence in service, innovation, and unwavering commitment to client success. We envision a future where our technology solutions drive meaningful impact, fostering progress and transformation across industries."
                    link="/features-2"
                    containerClass="app-feature-single-1"
                  />
                </div>
                <div
                  className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1"
                  style={{ marginTop: '2rem' }}
                >
                  <AppFeatureSingle
                    icon="las la-handshake"
                    heading="Join Us"
                    excerpt="Partner with Avodo Corp to leverage the power of technology and achieve your business goals. Explore our services and discover how we can help you navigate the digital landscape with confidence. Together, let’s drive innovation and shape the future of technology."
                    link="/features-2"
                    containerClass="app-feature-single-1"
                  />
                </div>
              </div>
            </Suspense>

            {/* second half - end */}
          </div>
        </div>
      </div>
      {/* about section - end */}

      {/* testimonial section - start */}
      <div className="testimonial-section">
        <div className="testimonial-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <SectionHeading
                  icon="las la-comments"
                  heading="feedbacks"
                  subHeading="What people are talking about."
                  additionalClasses="center width-55"
                />
              </div>
            </div>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <div className="container">
              <div className="row">
                <TestimonialSlider />
              </div>
            </div>
          </Suspense>
        </div>
      </div>
      {/* testimonial section - end */}
    </>
  );
};

export default About;
