import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// pages
import Header from './layout/Header';
import Index from './pages/Index';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Footer from './layout/Footer';
import CustomScrollbar from './components/CustomScrollbar';
import Career from './pages/Career';
import BlogDetail from './pages/BlogDetail';

const Markup = () => {
  return (
    <>
      <CustomScrollbar />
      <div className="main-wrapper">
        {/* header - start */}
        <Header type={useLocation().pathname !== '/' ? 'navigation-1' : ''} />
        {/* header - end */}
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {/* footer - start */}
        <Footer />
        {/* footer - end */}
      </div>
    </>
  );
};

export default Markup;
